<template>
    <div class="w-full">
        <div class=" flex flex-col gap-2 animate-pulse">
            <div class="w-full h-4 rounded-md bg-slate-200"></div>
            <div class="w-full h-10 rounded-md bg-slate-200"></div>
            <div class="w-full h-10 rounded-md bg-slate-200"></div>
            <div class="w-full h-10 rounded-md bg-slate-200"></div>
            <div class="w-full h-4 rounded-md bg-slate-200"></div>
            <div class="w-full h-10 rounded-md bg-slate-200"></div>
            <div class="w-full h-10 rounded-md bg-slate-200"></div>
        </div>
    </div>
</template>